import React from 'react'
import './personalNerdBlockV4.css'
import LazyLoad from 'react-lazyload';
import { NavLink } from 'react-router-dom'

export default (props) => <div className='personal-nerd-blockV4'>
  <div className='wrapper2'>
    <div className='title'>{props.data.title}</div>
    <div className='subtitle'>{props.data.subtitle}</div>
    <LazyLoad height={155}>
      <div className='colleges-list' />
    </LazyLoad>
    <NavLink className="personal-nerd-btn" to="/become">Become a Nerd</NavLink>
  </div>
</div>
