import React from 'react';
import { NavLink } from 'react-router-dom';
import Holder from './../../../../themes/nerdify1/components/Holder';
import { Header2, HeaderIcon } from './../../../../themes/nerdify1/components/Header';
import Content from './../../../../themes/nerdify1/components/Content';
//import FooterV4 from './../../../../components/FooterV4/Footer.js'
//import { InputSelectLocales } from './../../../../themes/nerdify1/components/Form/InputSelect';

import { REACT_APP_CONTACT_EMAIL } from './../../../../config';
import LocalesPage from './../LocalesPage';

export default ({ className, children, ...rest }) => (
  <LocalesPage {...rest}>
    <Header2>
      <Holder>
        <NavLink className='logo-link' to='/'>
          <HeaderIcon iconName='logo-dark-1' />
        </NavLink>
      </Holder>
    </Header2>
    <Content>
      <Holder>
        <h1>Oops..</h1>
        <p>We can't find the page you're looking for, please text us via <a href={`mailto:${REACT_APP_CONTACT_EMAIL}`}>{REACT_APP_CONTACT_EMAIL}</a>.</p>
      </Holder>
    </Content>
  </LocalesPage>
);
