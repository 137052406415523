import React, { Component } from 'react'
import Icon from './../SvgIcons'
import './testimonialsV4.css'
//import testimonialsCfg from './config'

import moreImg from './img/more.png'
import moreImg2x from './img/more@2x.png'

class TestimonialItem extends Component {
  render() {
    const { img, name, brandname, date, text } = this.props;

    return (
      <li className='testimonial-list-item'>
        <span className='testimonial-list-item-link'>
          <span className='testimonial-list-item-main'>
            <span className='testimonial-list-item-user-avatar'>
              <img src={require(`${img}.jpg`)} srcSet={require(`${img}.jpg`) + ' 1x,' + require(`${img}@2x.jpg`) + ' 2x'} alt={name} />
            </span>
            <span className='testimonial-list-item-user-info'>
              <span className='testimonial-list-item-user-info-top'>
                <span className='testimonial-list-item-user-name'>{name}</span> reviewed <span
                  className='testimonial-list-item-group-name'>{ brandname ? brandname : 'Nerdify'}</span>
                <span className='testimonial-list-item-aux'> - </span>
                <span className='testimonial-list-item-star'>
                  <span>5 </span>
                  <Icon className='svg-icon' iconName='star-icon' />
                </span>
              </span>
              <span className='testimonial-list-item-user-info-bottom'>
                <span>{date} • </span>
                <Icon className='svg-icon' iconName='globe-icon' />
              </span>
            </span>
          </span>
          <span className='testimonial-list-item-post'>{text}</span>
        </span>
      </li>
    )
  }
}

export default class Testimonials extends Component {
  render() {
    const { moreReviews, data } = this.props
    // const {classNamesCfg, itemsCfg} = this.props
    const items = data;
    // const className = testimonialsCfg.classNames[classNamesCfg]
    const hasItems = items && items.length > 0

    if (!hasItems) {
      return null
    }

    return (
      <div className={`testimonial-blockV4`}>
        <div className='wrapper2'>
          <ul className='testimonial-list'>
            {items.map((item, key) => {
              return (
                <TestimonialItem
                  {...item}
                  key={`testimonial-list-item-${key}`}
                  index={key}
                />
              )
            })}
          </ul>
          {moreReviews &&
            <div className='more-reviews'>
              <img src={moreImg} srcSet={moreImg2x} alt="More reviews on Facebook and SiteJabber" />
            </div>}
        </div>
      </div>
    )
  }
}
