export default {
  themes: {
    1: {
      className: 'go-slider-block',
      title: {
        className: 'go-slider-title type1',
        text: 'Getting help from Nerds is just a few messages away'
      },
      subTitle: {
        text: 'You can ask for help with anything as long as it’s legal and doesn’t violate any college or corporate policies.\n',
      },
      iphoneIcon: {
        iconName: 'go-iphone-v4'
      }
    },
    2: {
      className: 'fbnew-slider-block',
      title: {
        className: 'fbnew-slider-title',
        text: 'What can the Nerds do?'
      },
      iphoneIcon: {
        iconName: 'go-iphone2'
      }
    },
    3: {
      className: 'hp-new-slider-block',
      iphoneIcon: {
        iconName: 'go-iphone2'
      }
    },
    4: {
      className: 'go-slider-block',
      title: {
        className: 'go-slider-title type1',
        text: 'Getting help from Nerds is just a few messages away'
      },
      iphoneIcon: {
        iconName: 'go-iphone'
      }
    },
    5: {
      className: 'go-slider-block',
      title: {
        className: 'go-slider-title type1',
        text: 'The professional help right at your fingertips'
      },
      subTitle: {
        text: 'You can ask for help with anything as long as it’s legal and doesn’t violate any college or corporate policies.\n',
      },
      iphoneIcon: {
        iconName: 'go-iphone-v4'
      }
    },
    6: {
      className: 'go-slider-block',
      title: {
        className: 'go-slider-title type1',
        text: 'Getting stuff done has never been so easy'
      },
      subTitle: {
        text: 'You can ask for help with anything as long as it’s legal and doesn’t violate any college or corporate policies.\n',
      },
      iphoneIcon: {
        iconName: 'go-iphone-v4'
      }
    },
    7: {
      className: 'go-slider-block',
      title: {
        className: 'go-slider-title type1',
        text: 'Enjoy the benefits of tech solutions for your personal needs'
      },
      subTitle: {
        text: 'You can ask for help with anything as long as it’s legal and doesn’t violate any college or corporate policies.\n',
      },
      iphoneIcon: {
        iconName: 'go-iphone-v4'
      }
    },
  },
  indicators: {
    1: [
      {
        className: 'go-slide-ind3',
        title: 'Tutoring',
        icons: [
          'go-slider-icon1',
          'go-slider-icon1a'
        ]

      },
      {
        className: 'go-slide-ind1',
        title: 'Editing',
        icons: [
          'go-slider-icon2',
          'go-slider-icon2a'
        ]
      },
      {
        className: 'go-slide-ind2',
        title: 'Proofreading',
        icons: [
          'go-slider-icon4',
          'go-slider-icon4a'
        ]
      }
    ],
    2: [
      {
        className: 'go-slide-ind1',
        title: 'Write papers from scratch',
        icons: [
          'go_slider_ico1'
        ]

      },
      {
        className: 'go-slide-ind2',
        title: 'Edit your papers',
        icons: [
          'go_slider_ico2'
        ]
      },
      {
        className: 'go-slide-ind3',
        title: 'Create flawless presentations',
        icons: [
          'go_slider_ico3'
        ]
      },
      {
        className: 'go-slide-ind4',
        title: 'Get any online tasks done',
        icons: [
          'go_slider_ico4'
        ]
      }
    ],
    3: [
      {
        className: 'go-slide-ind1',
        title: 'Editing',
        icons: [
          'go-slider-icon2',
          'go-slider-icon2a'
        ]
      },
      {
        className: 'go-slide-ind2',
        title: 'Proofreading',
        icons: [
          'go-slider-icon4',
          'go-slider-icon4a'
        ]
      }
    ]
  },
  images: {
    1: [
      {
        img: './img/1.jpg',
        alt: 'request example'
      },
      {
        img: './img/2.jpg',
        alt: 'request example'
      },
      {
        img: './img/3.jpg',
        alt: 'request example'
      },
      {
        img: './img/4.jpg',
        alt: 'request example'
      }
    ],
    2: [
      {
        img: './img/frame1.jpg',
        alt: 'Nerdify Bot can help you solve equations'
      },
      {
        img: './img/frame2.jpg',
        alt: 'Nerdify Bot can look up definitions'
      },
      {
        img: './img/frame3.jpg',
        alt: 'Nerdify Bot can plot graphs'
      },
      {
        img: './img/frame4.jpg',
        alt: 'Nerdify Bot can help you find the info you are looking for'
      }
    ],
    3: [
      {
        img: './img/screen_4.png',
        alt: 'Nerdify nerds can proofread a paper you have to turn in tomorrow'
      },
      {
        img: './img/screen_2.png',
        alt: 'Nerdify can get textbooks delivered to your door'
      },
      {
        img: './img/screen_3.png',
        alt: 'Nerdify nerds can explain even the most complex problems and tasks to you'
      },
      {
        img: './img/screen_1.png',
        alt: 'Nerdify can find a suitable nerd to help you prepare for your midterm exams'
      }
    ],
    4: [
      {
        img: './img/frame2.jpg',
        alt: 'Our Nerds can edit your paper to make sure it meets all formatting requirements'
      },
      {
        img: './img/frame3a.jpg',
        alt: 'Our Nerds can proofread a paper you have to turn in tomorrow'
      }
    ],
    5: [
      {
        img: './img/the_1.png',
        alt: 'Business Task'
      },
      {
        img: './img/the_2.png',
        alt: 'Find Employee'
      },
      {
        img: './img/the_3.png',
        alt: 'Personal Nerd'
      },
      {
        img: './img/the_4.png',
        alt: 'Personal Nerd'
      }
    ],
    6: [
      {
        img: './img/n-it_1.png',
        alt: 'Personal Nerd'
      },
      {
        img: './img/n-it_2.png',
        alt: 'Book delivery'
      },
      {
        img: './img/n-it_3.png',
        alt: 'Misc Task'
      },
      {
        img: './img/n-it_4.png',
        alt: 'Internship'
      }
    ],
    7: [
      {
        img: './img/gonerdify.net_Book_delivery.png',
        alt: 'request example'
      },
      {
        img: './img/gonerdify.net_Personal_Nerd.png',
        alt: 'request example'
      },
      {
        img: './img/gonerdify.net_Presentation_help.png',
        alt: 'request example'
      },
      {
        img: './img/gonerdify.net_University.png',
        alt: 'request example'
      }
    ]
  },
  controls: {
    1: [
      {
        className: 'mob-arrow',
        iconName: 'icon-slider-arrow2'
      },
      {
        className: 'desk-arrow',
        iconName: 'icon-slider-arrow'
      }
    ],
    2: [
      {
        className: 'mob-arrow',
        iconName: 'icon-slider-arrow2'
      },
      {
        className: 'desk-arrow',
        iconName: 'icon-slider-arrow'
      }
    ]
  }
}
