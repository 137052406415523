import React from 'react'
import LazyLoad from 'react-lazyload';
import Icon from './../SvgIcons'
import './pressBlock.css'

export default () => <div className='press-about-us-block'>
  <div className='wrapper1'>
    <p className='press-about-us-title'>Featured</p>
    <ul className="press-about-us-list">
      <li className="press-about-us-item">
        <LazyLoad height={33}>
        <Icon className='press-about-us-logo forbes-logo' iconName="forbes" />
        </LazyLoad>
      </li>
      <li className="press-about-us-item">
        <LazyLoad height={33}>
        <Icon className='press-about-us-logo wired-logo' iconName="wired" />
        </LazyLoad>
      </li>
      <li className="press-about-us-item">
        <LazyLoad height={33}>
        <Icon className='press-about-us-logo venturebeat-logo' iconName="venturebeat" />
        </LazyLoad>
      </li>
      <li className="press-about-us-item">
        <LazyLoad height={33}>
        <Icon className='press-about-us-logo tech-co-logo' iconName="tech-co" />
        </LazyLoad>
      </li>
      <li className="press-about-us-item">
        <LazyLoad height={33}>
        <Icon className='press-about-us-logo nextmedia-logo' iconName="next-media" />
        </LazyLoad>
      </li>
      {/*<li className="press-about-us-item last-item">
        <Icon className='press-about-us-logo nulltx-logo' iconName="nulltx" />
      </li>*/}
    </ul>
  </div>
</div>
