import React from 'react';
import { LocalesHOC } from '../../../../base_components/Locales';

const LocalesPage = (props) => {
  return (
    <div className="localized">
      {props.children}
    </div>
  )
};

export default LocalesHOC(LocalesPage);
