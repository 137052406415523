import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { REACT_APP_CONTACT_EMAIL } from '../../../../config'
import TextPage from './../TextPage';

export default class Terms extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: {
        answer1: false,
        answer2: false,
        answer3: false,
        answer4: false,
        answer5: false,
        answer6: false,
        answer7: false,
        answer8: false,
        answer9: false,
        answer10: false,
        answer11: false,
        answer12: false,
      },
    }
    this.toggleActive = this.toggleActive.bind(this)
  }

  toggleActive(e, el) {
    const { active } = this.state;

    this.setState({
      active: {
        [el]: !active[el]
      }
    });
  }

  render() {
    const { active } = this.state;

    return (
      <TextPage {...this.props} >
        <h1>Terms of Use</h1>

        <div className='accordion' id='accordion2'>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2
                onClick={(e) => this.toggleActive(e, 'answer1')}
                className={`accordion-toggle question ga_ev ${active.answer1 ? 'active' : ''}`}
                data-gacat='faq'
                data-gaact='asking_nerdify'
                data-toggle='collapse'
              >
                Terms of use
                </h2>
            </div>
            <div id='answer1' className={`accordion-body collapse ${active.answer1 ? 'in' : ''}`}>
              <div className='accordion-inner section-content'>
                <p>Welcome to Nerdify! <br /> You agree to comply and be bound by the terms of use set below and your local domestic laws. If you do not agree to these terms, you should not use our website. The terms Nerdify refer to as “our”, “us”, and “we”, while “you” refers to our users. The following terms are entered into force once  you visit our website. These terms are valid for a certain time frame. However, these terms are not rendered invalid even if some provisions listed here become unenforceable.</p>
                <div className='accordion-toggle close-btn' onClick={(e) => this.toggleActive(e, 'answer1')}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2
                onClick={(e) => this.toggleActive(e, 'answer2')}
                className={`accordion-toggle question ga_ev ${active.answer2 ? 'active' : ''}`}
                data-gacat='faq'
                data-gaact='asking_nerdify'
                data-toggle='collapse'
              >
                Our Terms of Use
                </h2>
            </div>
            <div id='answer2' className={`accordion-body collapse ${active.answer2 ? 'in' : ''}`}>
              <div className='accordion-inner section-content'>
                <p>Our platform designed to help Clients connect with a Nerd or a vendor. They provide services to users, but these are not limited to:</p>
                <ul className='ul-typeV4'>
                  <li>Finding internships and colleges</li>
                  <li>Looking for and purchasing textbooks</li>
                  <li>Hiring Nerds or vendors </li>
                </ul>
                <p>Vendors - They act as our Client’s personal assistant for their orders. Our technology helps our Clients connect with these Nerds via online text messaging. </p>
                <p>We do not provide any other services, and we do not represent our Nerds. Consider our Nerds as independent contractors/employees, not the representatives of Nerdify. Moreover, we condemn conducting illegal acts on the user’s university/college.</p>
                <p>The company ensures that we conduct safety checks to ensure that none of our actions violate any educational policy. However, we cannot be responsible for the goods and services that the Nerd will provide to his/her Client.</p>

                <div className='accordion-toggle close-btn' onClick={(e) => this.toggleActive(e, 'answer2')}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2
                onClick={(e) => this.toggleActive(e, 'answer3')}
                className={`accordion-toggle question ga_ev ${active.answer3 ? 'active' : ''}`}
                data-gacat='faq'
                data-gaact='asking_nerdify'
                data-toggle='collapse'
              >
                Our Contact Information Usage
                </h2>
            </div>
            <div id='answer3' className={`accordion-body collapse ${active.answer3 ? 'in' : ''}`}>
              <div className='accordion-inner section-content'>
                <ul className='ul-typeV4'>
                  <li>Your phone will be used as the medium of communication. Hence, SMS or text messaging is our way to deliver our goods and services to you. </li>
                  <li>We use Amazon Web Hostings, one of the most secure hosting networks globally, to ensure that your phone numbers are safely stored in our domain. </li>
                  <li>Your number will not be relayed or given to any third party. In fact, only Nerdify’s employees can find it out. </li>
                  <li>Nerdify may reach out to you for marketing offers and requests</li>
                  <li>Please write “Stop text” if you wish to not receive any messages from us. </li>
                  <li>We don’t charge you any fees, however, your respective mobile networks may charge you once you text our number. </li>
                </ul>
                <div className='accordion-toggle close-btn' onClick={(e) => this.toggleActive(e, 'answer3')}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2
                onClick={(e) => this.toggleActive(e, 'answer4')}
                className={`accordion-toggle question ga_ev ${active.answer4 ? 'active' : ''}`}
                data-gacat='faq'
                data-gaact='asking_nerdify'
                data-toggle='collapse'
              >
                Nerdify’s Content and Copyright: Usage
                </h2>
            </div>
            <div id='answer4' className={`accordion-body collapse ${active.answer4 ? 'in' : ''}`}>
              <div className='accordion-inner section-content'>
                <p>Nerdify holds the copyright on all text, images, trademarks, and logos in this website, other than those of third parties. The copyright law and database rights protect us, therefore, you may not copy or transfer our content, and any content that has our trade name (Nerdify) other than the company itself is a breach of our copyright. </p>
                <div className='accordion-toggle close-btn' onClick={(e) => this.toggleActive(e, 'answer4')}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2
                onClick={(e) => this.toggleActive(e, 'answer5')}
                className={`accordion-toggle question ga_ev ${active.answer5 ? 'active' : ''}`}
                data-gacat='faq'
                data-gaact='asking_nerdify'
                data-toggle='collapse'
              >
                Refund
                </h2>
            </div>
            <div id='answer5' className={`accordion-body collapse ${active.answer5 ? 'in' : ''}`}>
              <div className='accordion-inner section-content'>
                <p>As per our <NavLink to='/refunds'>refund policy</NavLink>, we may find you another vendor to redo your order for free or fully refund your payment to your credit card or balance. Refunds have to be made at least 30 days after the product is delivered to you. Moreover, please expect us to refer you to another vendor within 24 hours and around. Feel free to contact us via email!</p>
                <div className='accordion-toggle close-btn' onClick={(e) => this.toggleActive(e, 'answer5')}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2
                onClick={(e) => this.toggleActive(e, 'answer6')}
                className={`accordion-toggle question ga_ev ${active.answer6 ? 'active' : ''}`}
                data-gacat='faq'
                data-gaact='asking_nerdify'
                data-toggle='collapse'
              >
                Safety
                </h2>
            </div>
            <div id='answer6' className={`accordion-body collapse ${active.answer6 ? 'in' : ''}`}>
              <div className='accordion-inner section-content'>
                <p>You agree not to do the following: </p>
                <ul className='ul-typeV4'>
                  <li>Collect data/information and access Nerdify’s website using bots, spiders, etc. without permission;</li>
                  <li>Conduct and engage in unlawful marketing activities;</li>
                  <li>Spread or upload viruses and malware;</li>
                  <li>Solicit, ask, and share users’ information;</li>
                  <li>Bully, harass or threaten our employees and other users;</li>
                  <li>Use or allow our company to do illegal, malicious, and discriminatory activities;</li>
                  <li>Disable the full functionality of Nerdify’s website (ex: Service denial);</li>
                  <li>Facilitate or encourage anyone to violate our terms of use.</li>
                </ul>
                <br />
                <p>We don’t process requests concerning the violation of laws, statutes, and ordinances and the following:</p>
                <ul className='ul-typeV4'>
                  <li>Connections with drugs and alcohol;</li>
                  <li>Services or items that may promote unlawfulness;</li>
                  <li>Pirated digital goods;</li>
                  <li>Hate speech, violence, racism, etc;</li>
                  <li>Any obscene content that may offend/hurt a person;</li>
                  <li>Copyright infringement and violation of laws;</li>
                  <li>Sexually explicit materials and services;</li>
                  <li>Ammunition and projectiles for firearms and the like;</li>
                  <li>Weapons;</li>
                  <li>Sharing and revealing third parties’ information;</li>
                  <li>Involvement in making quick and easy money and purchasing layaway systems or lottery contests;</li>
                  <li>Putting items for sale before the seller has taken control of the said items;</li>
                  <li>Collect payments for vendors;</li>
                  <li>Associate a user with selling travel cheques and other related activities;</li>
                  <li>Involve in debt settlement services, offers, and the like;</li>
                  <li>Requests that are deemed fraudulent by local governments.</li>
                </ul>
                <br />
                <p>You are not allowed to write or spread content that includes:</p>
                <ul className='ul-typeV4'>
                  <li>Curse words or offensive words/statements that may offend, embarrass, or upset any user;</li>
                  <li>Obscene statements that are offensive to human dignity;</li>
                  <li>Promoting racism, sexism, bigotry, colorism, and hatred;</li>
                  <li>Representing a minor’s image;</li>
                  <li>Nudity and graphic violence;</li>
                  <li>Endorsing commercial activities such as linking to websites and contests;</li>
                  <li>Transmitting junk mail and spam;</li>
                  <li>Embedding and spreading viruses, spyware, malware, and the like that may respectively violate or malfunction any third parties’ rights and services.</li>
                </ul>
                <div className='accordion-toggle close-btn' onClick={(e) => this.toggleActive(e, 'answer6')}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2
                onClick={(e) => this.toggleActive(e, 'answer7')}
                className={`accordion-toggle question ga_ev ${active.answer7 ? 'active' : ''}`}
                data-gacat='faq'
                data-gaact='asking_nerdify'
                data-toggle='collapse'
              >
                Subscription
                </h2>
            </div>
            <div id='answer7' className={`accordion-body collapse ${active.answer7 ? 'in' : ''}`}>
              <div className='accordion-inner section-content'>
                <p>Benefits of subscribing to our services include:</p>
                <ul className='ul-typeV4'>
                  <li>Priority response;</li>
                  <li>VIP numbers to call and text;</li>
                  <li>Progress updates and more.</li>
                </ul>
                <p>Payment is deducted from your credit card balance via Stripe every month on the day (or the closest day)you made an account and paid for your subscription. </p>
                <div className='accordion-toggle close-btn' onClick={(e) => this.toggleActive(e, 'answer7')}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2
                onClick={(e) => this.toggleActive(e, 'answer8')}
                className={`accordion-toggle question ga_ev ${active.answer8 ? 'active' : ''}`}
                data-gacat='faq'
                data-gaact='asking_nerdify'
                data-toggle='collapse'
              >
                Limitation of Liability
                </h2>
            </div>
            <div id='answer8' className={`accordion-body collapse ${active.answer8 ? 'in' : ''}`}>
              <div className='accordion-inner section-content'>
                <p>Nerdify and its partners are not liable for any damages including, but not limited to direct, indirect, and economic losses that may or may not be directly or indirectly related to:</p>
                <ul className='ul-typeV4'>
                  <li>Services;</li>
                  <li>Content;</li>
                  <li>User content;</li>
                  <li>Your usage of the website;</li>
                  <li>Investigations conducted by officials;</li>
                  <li>An action relating to copyright holders;</li>
                  <li>Technical errors/omissions;</li>
                  <li>Damages made to another user’s device. However, this is not limited to security breaches — technical glitches and errors, network failure, and the like.</li>
                </ul>
                <div className='accordion-toggle close-btn' onClick={(e) => this.toggleActive(e, 'answer8')}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2
                onClick={(e) => this.toggleActive(e, 'answer9')}
                className={`accordion-toggle question ga_ev ${active.answer9 ? 'active' : ''}`}
                data-gacat='faq'
                data-gaact='asking_nerdify'
                data-toggle='collapse'
              >
                Termination of Cooperation
                </h2>
            </div>
            <div id='answer9' className={`accordion-body collapse ${active.answer9 ? 'in' : ''}`}>
              <div className='accordion-inner section-content'>
                <p>You can terminate your account and delete your personal information by sending us a quick message on <a href={`mailto:${REACT_APP_CONTACT_EMAIL}`} rel="noopener noreferrer">{REACT_APP_CONTACT_EMAIL}</a>. Your decision will be under review for one to two business days. You, therefore, agree that we may limit or end your access to certain websites and services without prior notice.</p>
                <div className='accordion-toggle close-btn' onClick={(e) => this.toggleActive(e, 'answer9')}>close</div>
              </div>
            </div>
          </div>


          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2
                onClick={(e) => this.toggleActive(e, 'answer10')}
                className={`accordion-toggle question ga_ev ${active.answer10 ? 'active' : ''}`}
                data-gacat='faq'
                data-gaact='asking_nerdify'
                data-toggle='collapse'
              >
                Disclaimer
                </h2>
            </div>
            <div id='answer10' className={`accordion-body collapse ${active.answer10 ? 'in' : ''}`}>
              <div className='accordion-inner section-content'>
                <ul className='ul-typeV4'>
                  <li>Nerdify only acts as an intermediary between users and vendors. We do not take part in any agreement/s between the user and vendor.</li>
                  <li>We screen our vendors as soon as they apply to our website. However, we cannot verify their competency/proficiency and background. We do not have any control over vendors’/users’ services and actions. Meetups outside of the platform are made on the user or vendor’s risk.</li>
                </ul>
                <p>By accessing this site, you agree:</p>
                <ul className='ul-typeV4'>
                  <li>That legal actions can be taken by the user but is limited to the user’s vendor or third parties that caused the damage; </li>
                  <li>To no seek legal help from us. We are not responsible for the accuracy, truthfulness, and the quality of information shared between the user and vendor. Therefore, we encourage you to cooperate with anyone you contact about the company’s website and its services.</li>
                </ul>
                <div className='accordion-toggle close-btn' onClick={(e) => this.toggleActive(e, 'answer10')}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2
                onClick={(e) => this.toggleActive(e, 'answer11')}
                className={`accordion-toggle question ga_ev ${active.answer11 ? 'active' : ''}`}
                data-gacat='faq'
                data-gaact='asking_nerdify'
                data-toggle='collapse'
              >
                Links
                </h2>
            </div>
            <div id='answer11' className={`accordion-body collapse ${active.answer11 ? 'in' : ''}`}>
              <div className='accordion-inner section-content'>
                <p>We have not analyzed any links to our content directly. We are not responsible for such, therefore, using external links are solely on the user’s risk.</p>
                <div className='accordion-toggle close-btn' onClick={(e) => this.toggleActive(e, 'answer11')}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2
                onClick={(e) => this.toggleActive(e, 'answer12')}
                className={`accordion-toggle question ga_ev ${active.answer12 ? 'active' : ''}`}
                data-gacat='faq'
                data-gaact='asking_nerdify'
                data-toggle='collapse'
              >
                Modifications
                </h2>
            </div>
            <div id='answer12' className={`accordion-body collapse ${active.answer12 ? 'in' : ''}`}>
              <div className='accordion-inner section-content'>
                <p>We have the right to edit our terms of use without prior notice. As a user, you are bound by the current version of  Nerdify’s terms.</p>
                <div className='accordion-toggle close-btn' onClick={(e) => this.toggleActive(e, 'answer12')}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2
                onClick={(e) => this.toggleActive(e, 'answer13')}
                className={`accordion-toggle question ga_ev ${active.answer13 ? 'active' : ''}`}
                data-gacat='faq'
                data-gaact='asking_nerdify'
                data-toggle='collapse'
              >
                Vendor’s Pledge
                </h2>
            </div>
            <div id='answer13' className={`accordion-body collapse ${active.answer13 ? 'in' : ''}`}>
              <div className='accordion-inner section-content'>
                <p>As a vendor of Nerdify, you agree to the following statements: </p>
                <ul className='ul-typeV4'>
                  <li>I possess the qualifications and expertise to provide my Client with quality and truthful services; </li>
                  <li>I will not use the platform to violate any academic policies in American and British universities; </li>
                  <li>I will not encourage my Client or any other user to engage or conduct any fraudulent acts or academic dishonesty; </li>
                  <li>I understand that my account will be terminated due if I commit any illegal activity in the platform; </li>
                  <li>I will not take part in any activity that allows Nerdify to pay royalties/licences from third parties (ex:Recordings of live performances of any musical art). </li>
                </ul>
                <div className='accordion-toggle close-btn' onClick={(e) => this.toggleActive(e, 'answer13')}>close</div>
              </div>
            </div>
          </div>

        </div>
      </TextPage>

    )
  }
}
