import React from 'react';
import classnames from 'classnames';

import {
  WithClassNameHOC,
} from '../../../../base_components/Hoc';

import './index.css';



const Hero = ({ className, ...rest }) => <div {...rest} className={classnames('hero', className)} />;
const Hero1 = ({className, ...rest}) => <Hero {...rest} className={classnames('hero_1', className)} />;
export const Hero2 = ({className, ...rest}) => <div {...rest} className={classnames('hero_2', className)} />; // thank-you page

export const Hero1_1 = WithClassNameHOC(Hero1, 'hero_1_1');
export const Hero1_2 = WithClassNameHOC(Hero1, 'hero_1_2');
export const Hero1_3 = WithClassNameHOC(Hero1, 'hero_1_3');
