import React from 'react';
import { NavLink } from 'react-router-dom';
import Holder from './../../../../themes/nerdify1/components/Holder';
import { Header2, HeaderIcon } from './../../../../themes/nerdify1/components/Header';

import Content from './../../../../themes/nerdify1/components/Content';

import FooterV4 from './../../../../components/FooterV4/Footer.js'
import LocalesPage from './../LocalesPage';

export default ({ className, children, ...rest }) => (
  <LocalesPage {...rest}>
    <Header2>
      <Holder>
        <NavLink className='logo-link' to='/'>
          <HeaderIcon iconName='logo-dark-1' />
        </NavLink>
      </Holder>
    </Header2>
    <Content>
      <Holder>
        {children}
      </Holder>
    </Content>
    <FooterV4 />
  </LocalesPage>
)
