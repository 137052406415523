import React from 'react';
import { NavLink } from 'react-router-dom';
import Text from '../../../../base_components/Text'

import { TextBackTheme1, TextBackTheme2, TextBackForm } from './../../../../themes/nerdify1/components/TextBack';

const TextBackContent = (props) => (
  <Text>
    <p className="p textback__label">Enter your phone number and we will text you asap</p>
    <TextBackForm {...props} />
    <p className="p__tiny textback__agree">
    By submitting your number you agree to our
      {' '}
      <NavLink className='link a__underline' to='/terms-and-conditions'>Terms of Use</NavLink>,
      {' '}
      <NavLink className='link a__underline' to='/privacy-policy'>Privacy Policy</NavLink>
      {' '} and {' '}
      <NavLink className='link a__underline' to='/cookies'>Cookie Policy</NavLink>.
    </p>
  </Text>
);

export const TextBack1 = (props) => <TextBackTheme1>
  <TextBackContent {...props} />
</TextBackTheme1>;

export const TextBack2 = (props) => <TextBackTheme2>
  <TextBackContent {...props} />
</TextBackTheme2>;

export const TextBack1a = (props) => <TextBackTheme1>
  <TextBackForm {...props} />
</TextBackTheme1>;
