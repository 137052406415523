import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './faq.css'
//import faqCfg from './config'

/**
 * @class FaqItem
 * @memberOf Components
 * @param {Object} props
 */

class FaqItem extends Component {
  constructor(props) {
    super(props)

    this._handleOnClickGetElem = this._handleOnClickGetElem.bind(this)
  }

  static propTypes = {
    text: PropTypes.string,
    title: PropTypes.string,
    index: PropTypes.number,
    activeIndex: PropTypes.number,
    toggleActive: PropTypes.func,
    handleOnClickClose: PropTypes.func
  }

  _handleOnClickGetElem(e, index) {
    e.preventDefault()
    this.props.toggleActive(this.el, index)
  }

  render() {
    const { index, activeIndex, text, title } = this.props
    const isActive = (activeIndex === index)

    return (
      <div className='accordion-group'>
        <div className='accordion-heading'>
          <div
            onClick={(e) => this._handleOnClickGetElem(e, index)}
            className={`accordion-toggle question ga_ev ${isActive ? 'active' : ''}`}
            data-gacat='faq'
            data-gaact='asking_nerdify'
            data-toggle='collapse'
          >
            {title}
          </div>
        </div>
        <div
          className={`accordion-body collapse ${isActive ? 'in' : ''}`}
          ref={(el) => (this.el = el)}>
          <div className='accordion-inner clearfix'>
            {/* <a
              onClick={this.props.handleOnClickClose}
              className='accordion-toggle close-btn' data-toggle='collapse'
              href=''><Icon className='svg-icon' iconName='close-btn-icon' /></a> */}
            <p className='accordion-msg'>{text}</p>
          </div>
        </div>
      </div>
    )
  }
}

/**
 * @class Faq
 * @memberOf Components
 * @param {Object} props
 */

export default class Faq extends Component {
  constructor(props) {
    super(props)

    this._toggleActive = this._toggleActive.bind(this)
    this._handleOnClickClose = this._handleOnClickClose.bind(this)

    this.state = {
      activeIndex: undefined,
      contentHeight: 0
    }
  }

  static propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    items: PropTypes.array
  }

  _toggleActive(el, index) {
    const { activeIndex } = this.state
    const isSameIndex = activeIndex === index

    this.setState({
      activeIndex: isSameIndex ? undefined : index
    }, () => {
      this.setState({
        contentHeight: isSameIndex ? 0 : el.clientHeight
      })
    })
  }

  _handleOnClickClose(e) {
    e.preventDefault()
    this.setState({
      activeIndex: undefined,
      contentHeight: 0
    })
  }

  render() {
    const { data } = this.props
    const title = data.title;
    const items = data.items;
    const hasItems = items && items.length > 0
    const { activeIndex, contentHeight } = this.state

    if (!hasItems) {
      return null
    }

    return (
      <div id='faq-block' className="faq-block fbnew-faq-block">
        <div className='wrapper1 wide-screens'>
          <p className='title'>{title}</p>
          <div className='accordion' id='accordion2'>
            {items.map((item, key) => {
              return (
                <FaqItem
                  {...item}
                  activeIndex={activeIndex}
                  index={key}
                  key={`accordion-item-${key}`}
                  toggleActive={this._toggleActive}
                  handleOnClickClose={this._handleOnClickClose}
                />
              )
            })}
          </div>
          <div className='filler' style={{ height: contentHeight }} />
        </div>
      </div>
    )
  }
}
