import React from 'react';

import Hiw, {
  HiwList,
  HiwItem,
  HiwImg as HiwImgBase,
  HiwText,
} from '../../../../base_components/Hiw';

import './index.css';

export const Hiw1 = (props) => {
  return <Hiw {...props} className={'hiw_1'} />
};

const HiwImg = ({img, className, ...rest}) => <HiwImgBase {...rest}>
  <img className={className} src={require(`./img/${img}.svg`)} alt={''}/>
</HiwImgBase>;

HiwImg.displayName = 'HiwImg';

export {
  HiwList,
  HiwImg,
  HiwItem,
  HiwText,
}
