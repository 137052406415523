import React from 'react';
import classnames from 'classnames';

import Modal from '../../../../base_components/Modal';
import Button from '../../../../base_components/Button';

import InputError from '../Form/InputError';

import { Form } from '../Form';

import InputSelect from '../../../../themes/nerdify/components/Form/InputSelect';
import { useLocalesModal } from '../../../../base_components/Locales';


import './modal.css';

//export default (props) => <Modal {...props} />

export const ModalChangeLocales = () => {
  const { country, countryCode, items, ...rest } = useLocalesModal();

  return (
    <Modal {...rest}>
      <div className="modal__locale-change">
        <h6 className="h6">Hey! Looks like you are from <span className="strong">{country}</span>. You might want<br /> to switch to another version<br /> of the website.</h6>
        <InputSelect
          name='country'
          value={country}
          countryCode={countryCode}
          items={items}
          onChange={rest.onChange}
          isError={false}
        />
        <Button className="button__type6" onClick={rest.onSave}>yes, good idea</Button>
        <Button className="a" onClick={rest.onClose}>No, I’m fine here</Button>
      </div>
    </Modal>
  )
};

export const ModalTextBackWithEmailSuccess = (props) => {
  const { isOpen, ...rest } = props;

  return (
    <Modal isOpen={isOpen}>
      <h3 className="h3">We'll text you <br /> in a second!</h3>
      <img className="modal-image modal-image__thumb" src={require(`./img/thumb.svg`)} alt="" />

      <ModalTextBackWithEmailForm
        {...rest}
      />
    </Modal>
  )
};

export const ModalTextBackWithEmailRepeat = (props) => {
  const { isOpen, ...rest } = props;

  return (
    <Modal isOpen={isOpen}>
      <h3 className="h3">We have already <br /> texted you a&nbsp;few <br /> minutes ago.</h3>
      <h6 className="h6">Please check your text messages</h6>
      <ModalTextBackWithEmailForm
        {...rest}
      />
    </Modal>
  )
};

const ModalTextBackWithEmailForm = (props) => {
  const { email, isPatching, leadCreated, errors } = props;

  if (leadCreated) {
    return (
      <Form>
        <h6 className="h6">Enter your email to receive <br /> Nerdify discounts and promos</h6>
        <fieldset className="fieldset">
          <div className="input-wrapper">
            <input
              className={classnames("input", { "input__error": errors.email })}
              type="email"
              name="email"
              value={email}
              placeholder="Email"
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="email"
              onChange={props.onChange}
            />
            {errors.email && <InputError>{errors.email}</InputError>}
          </div>
        </fieldset>
        <fieldset className="fieldset">
          <Button className="button__type6" onClick={props.onSubmit} disable={isPatching}>Submit email</Button>
        </fieldset>
        <fieldset className="fieldset">
          <Button className="a" onClick={props.onClose}>
            <strong>No, thank you</strong>
          </Button>
        </fieldset>
      </Form>
    )
  }

  return <Button className="a" onClick={props.onClose}>
    <strong>Close</strong>
  </Button>
}
