import React, { Component } from 'react';
// import classnames from 'classnames';
import './Dropdown.css';

const Panel = props => (

  <div className="wrapper">
    {props.children}
  </div>
);

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.clickAttached = false;
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
  }

  componentDidMount() {
    this.checkAttachClickOutside();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.checkAttachClickOutside();
    }
  }

  componentWillUnmount() {
    window.document.removeEventListener('click', this.handleDocumentClick);
  }

  checkAttachClickOutside() {
    const { isOpen, onOpenChange } = this.props;
    if (!onOpenChange) {
      return;
    }
    if (isOpen) {
      if (this.clickAttached) {
        return;
      }
      window.document.addEventListener('click', this.handleDocumentClick);
      this.clickAttached = true;
    } else {
      if (!this.clickAttached) {
        return;
      }
      window.document.removeEventListener('click', this.handleDocumentClick);
      this.clickAttached = false;
    }
  }

  handleDocumentClick(event) {
    const { onOpenChange } = this.props;
    if (!this.elementRef) {
      return;
    }
    if (!this.elementRef.contains(event.target)) {
      onOpenChange(false, event);
    }
  }

  render() {
    const { isOpen, children } = this.props;
    return (
      <div className="ddwn-container" ref={(ref) => { this.elementRef = ref; }}>
        {isOpen &&
          <div className="ddwn-content width_block">
            {children}
          </div>
        }
      </div>
    );
  }
}

export { Dropdown, Panel };
