import React, { useState, useEffect } from 'react';
import LocalesPage from '../LocalesPage';
import classnames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import Holder from './../../../../themes/nerdify1/components/Holder';
import { Header4, HeaderIcon } from '../../../../themes/nerdify1/components/Header';
import BackLink from "../../../../themes/nerdify1/components/BackLink";
import { Hero2 } from '../../../../themes/nerdify1/components/Hero';
import { TextBack1a } from '../../blocks/TextBack';
import RateBlockV4 from '../../../../components/RateBlockV4/RateBlockV4.js'
import TestimonialsV4 from '../../../../components/TestimonialsV4/TestimonialsV4.js'
import FAQV4 from '../../../../components/FAQV4/FAQ.js'

import FooterV4 from '../../../../components/FooterV4/Footer.js'
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';


import {
  dataFaq,
  dataTestimonials
} from '../Landing/data';


import { updateUser, checkLeadExists } from '../../../../redux/actions';

import regexps from '../../../../utils/regexps';
import { parseQuery } from '../../../../utils';

const ThankYou = (props) => {
  const { pageCfg } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const { isPatching } = useSelector((state) => state.textback);

  const [email, setEmail] = useState('');
  //const [userId, setUserId ] = useState('')
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const urlParams  = parseQuery(location.search);
  const phone  = props.location.state && props.location.state.phone || _.get(urlParams, 'to', '').split('-').join('') || '';
  const landing_type  = props.location.state && props.location.state.landing_type || _.get(urlParams, 'landing_type', '') || '';
  const source_url = props.location.state && props.location.state.source_url && decodeURIComponent(props.location.state.source_url) || decodeURIComponent(_.get(urlParams, 'source_url', '')) || '';

  useEffect(() => {
    localStorage.removeItem('leadCreated');
  }, []);

  const validate = () => {
    const errors = {
      email: !regexps.email(email.trim()) ? 'Required field' : null,
    };

    const isError = Object.keys(errors).some((item) => errors[item]);

    setErrors(errors);

    return isError;
  };

  const getUserIdHelper = async () => {
    const phone = _.get(parseQuery(window.location.search), 'to', '');

    if (phone) {
      const existsData = await dispatch(checkLeadExists(phone.split('-').join(''))).then(
        (resp) => resp.data
      );
      return existsData._id
    } else {
      return _.get(location, 'state.userId', '');
    }
  };

  const onSubmit = () => {
    const isError = validate();

    if (isError) {
      return false;
    }

    setErrors('');

    const userIdRes = getUserIdHelper();

    userIdRes.then(userId => {
      if (!userId) {
        return false;
      }

      setIsSubmitted(true);

      dispatch(updateUser(userId, email))
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          setErrors({
            server: error.text,
          });
        });
    })
  };

  const onChange = (e) => {
    const { value } = e.target;

    setEmail(value);
  };

  const isErrors = errors.server || errors.email;

  return (
    <LocalesPage {...props}>
      <div className="landing">
        <Header4>
          <Holder>
            <NavLink className='logo-link' to='/'>
              <HeaderIcon iconName='logo-dark-1' />
            </NavLink>
            {pageCfg.backLink && <BackLink path={source_url} phone={phone} />}
          </Holder>
        </Header4>

        <Hero2>
          <div className="hero_main">
            <div className="holder">
              <h1 className="hero__title">
                {landing_type === 'second' || landing_type === 'undelivered' ?
                  (
                    <>Done! We’ve just texted you again!</>
                  )
                  : (
                    <>We'll text you<br className="mobile-only" /> in a few seconds!</>
                  )
                }
              </h1>
              <h5 className="hero__subtitle1">
                {landing_type === 'second' || landing_type === 'undelivered' ?
                  (
                    <>Make sure you put in the correct phone number if you still don't get a message from us.</>
                  )
                  : (
                    <>If you don't receive our message within 15 sec,<br className="desktop-only"/>  please click the<br className="mobile-only"/> <strong>"Text me"</strong> button again.</>
                  )
                }
              </h5>

              <TextBack1a {...props} />
              {landing_type === 'undelivered' && (
                <div className="hero__warning">
                  If your phone number is correct and you still got no message from Nerdify - <br className="desktop-only"/>
                  please contact us via <a className="hero__link" href="mailto:done@nerdify-it.com">done@nerdify-it.com</a></div>
              )}

              {!isSubmitted ? (
                <>
                  <h5 className="hero__subtitle2">
                    Enter your email to receive Nerdify<br/> discounts and promos
                  </h5>
                  <div className={'email-form'}>
                    <div className="input-wrapper">
                      <input
                        className={classnames('email-input', {
                          input__error: isErrors,
                        })}
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Email"
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="email"
                        onChange={onChange}
                      />
                      {isErrors && (
                        <div className='input-error-text'>{errors.email || errors.server}</div>
                      )}
                    </div>
                    <button
                      className={classnames('btn2 submit-btn', {
                        submitted: isSubmitted || isPatching,
                      })}
                      disabled={isSubmitted || isPatching}
                      onClick={onSubmit}
                    >
                      {isPatching ? (
                        <ul className="loading">
                          <li />
                          <li />
                          <li />
                          <li />
                        </ul>
                      ) : (
                        <>
                          {isSubmitted ? 'Email submited' : 'Submit email'}
                        </>
                      )}
                    </button>
                  </div>
                </>
              ) : (
                <h5 className="hero__subtitle3">Thanks for sharing your email! <br/>
                  From now on you will be aware of Nerdify discounts<br/> and seasonal promos.</h5>
              )}
            </div>
          </div>
          <div className="hero_bottom">
            <div className="holder">
              <div className="title">Get to know more about our Nerds!</div>
              <p className="p">These are the "grade-A" students and graduates in our network from US and other world best universities willing to assist you with your task.</p>
            </div>
          </div>
        </Hero2>

        <RateBlockV4
          className='rate-blockV4'
        />

        <TestimonialsV4
          // classNamesCfg={1}
          // itemsCfg={1}
          moreReviews
          data={dataTestimonials}
        />


        <FAQV4
          // classNamesCfg={2}
          // titlesCfg={2}
          // itemsCfg={1}
          data={dataFaq}
        />

        <FooterV4
          {...props}
        />
      </div>
    </LocalesPage>
  );
};

export default ThankYou;
