import React from 'react';

import How, {
  HowDots,
  HowList,
} from '../../../../base_components/How';

import './index.css';

export default (props) => <How {...props} className={'how_1'} />

export const HowImg = ({img,alt}) => <img src={require(`./img/${img}.svg`)} alt={alt} />

export {
  HowDots,
  HowList,
}
