import React from 'react'
import { REACT_APP_CONTACT_EMAIL } from '../../../../config'
import TextPage from './../TextPage';

export default (props) => (
  <TextPage {...props} >

    <h1>Cookie Policy</h1>

    <h2>All About our Cookie Policy</h2>
    <div className="section-content">
      <p>Our company, partners, and affiliates may use cookies, tracking and identification technologies when you visit Nerdify’s website, including its applications and services.</p>
    </div>

    <h2>Our purpose is to:</h2>

    <div className="section-content">
      {/*        <p>Our purpose is to: </p>*/}
      <ul className='ul-typeV4'>
        <li>Identify and authenticate users and remember their settings and user preferences;</li>
        <li>Discern our user’s behavior and content popularity;</li>
        <li>Identify and analyze trends and web traffic;</li>
        <li>Administer and assess how effective our advertisements are. </li>
        <p>You can find out more about cookies and your choice of cookies here.</p>
      </ul>
    </div>

    <h2>Definition of a Cookie </h2>
    <div className="section-content">
      <p>Cookies are bits of data we store on your internet browser. They are unique to your account or your browser. There are various cookies we use on our website: </p>
      <ol className="ol-typeV4">
        <li>Session-based cookies are temporary when your browser is open. However, the said cookies are automatically deleted when you close your browser; </li>
        <li>Persistent cookies last until you or your browser delete them, or even until they expire;</li>
        <li>First-party cookies - they are created when you first access the Nerdify’s website;</li>
        <li>Third-party cookies - these type of cookies are set by companies and servers outside of Nerdify.</li>
      </ol>
    </div>

    <h2>Identification Technologies we use</h2>
    <div className="section-content">
      <p>As mentioned previously, we use the following identification technologies:</p>
      <ol className="ol-typeV4">
        <li><strong>Pixel Tags</strong> - these tags can find out the type of device and browser you are using, IP address, and time of visit. Third parties can read cookies from domains they do not own and operate. They collect information from the said domain by asking for the owner’s permission;</li>
        <li><strong>Local Storage</strong> - this is where information originating from browsers, advertisements, and third parties can be stored;</li>
        <li><strong>Software Development Kits (SDK)</strong> - they are akin to your pixels and cookies operating in a mobile application. The aforementioned does not usually function in mobile apps. The developer/s can install SDKs from their partners to collect information; </li>
      </ol>
    </div>

    <h2>Your Choices</h2>
    <div className="section-content">
      <p>You are granted the right to accept or decline cookies to be installed in your devices and browsers. Cookies are an essential part of our services. Nerdify’s services may be affected if you refuse to have cookies installed. </p>
      <ul className="ul-typeV4">
        <li>Majority of browsers are programmed to install cookies. You can always toggle your browser settings by clicking “Help” or “Preferences.” However, please take note that a number of third parties may allow you to decline their cookies through a link or a pop-up window. To find out how this circumstance is possible, please refer to the table below</li>
        <li>Third party flash cookies originating from Nerdify and our partners for the website’s services are not usually affected when you remove or reject cookies. You can get more information here: www.allaboutcookies.org or www.youronlinechoices.eu</li>
      </ul>
    </div>

    <h2>Types and Purposes of Cookies Nerdify use </h2>
    <div className="section-content">
      <p>The following table presents different kinds of cookies that our Services use and explains why we use them.</p>
      <table className='table-v4'>
        <thead>
        <tr>
          <th width="17%">Type of Cookie</th>
          <th>Purpose</th>
        </tr>
        </thead>

        <tbody>
        <tr>
          <td data-label="Type of Cookie">Essentials</td>
          <td data-label="Purpose">Provide functionality that help us deliver products and Services. For example, cookies may store login information that helps users enter the website easily. We may also use cookies and similar technologies to help us provide you with social plugins, other customized content and experiences, such as making suggestions to you and others.</td>
        </tr>
        <tr>
          <td data-label="Type of Cookie">Marketing</td>
          <td data-label="Purpose">Cookies and pixels are used to provide relevant ads, track ad campaign performance and the effectiveness of our marketing efforts. For example, we and our ad partners may rely on information collected via these cookies to show you ads that may interest you on other websites. Similarly, our partners may use cookies, attribution service or another similar technology to determine whether we’ve served an ad and how it performed.</td>
        </tr>
        <tr>
          <td data-label="Type of Cookie">Performance</td>
          <td data-label="Purpose">Help research, understand and improve products and Services. For example, when you access the Nerdify website or other related websites and apps from a computer or a mobile device. We may use cookies to understand your user experience on our platform. The collected data are used to improve and understand how you use websites, apps, products, services and ads.</td>
        </tr>
        <tr>
          <td data-label="Type of Cookie">Functional</td>
          <td data-label="Purpose">These cookies and similar technologies remember choices you make such as language or search parameters. We use these cookies to provide you with an experience more appropriate with your selections and to make your use of the Services more tailored.</td>
        </tr>
        </tbody>
      </table>
    </div>

    <h2>Contact Information</h2>
    <div className="section-content">
      <p>If you have clarifications, questions, and comments about our cookie policy, please send us a message at <a href={`mailto:${REACT_APP_CONTACT_EMAIL}`} rel="noopener noreferrer">{REACT_APP_CONTACT_EMAIL}</a>. We’re happy to know your thoughts to improve our services further. </p>
    </div>


  </TextPage>
)
