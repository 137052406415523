import React from 'react'
import LazyLoad from 'react-lazyload';
import './partnersV4.css'

export default (props) => <div className='partners-blockV4'>
  <div className='wrapper2'>
    <div className='title'>{props.data.title}</div>
    <LazyLoad height={95}>
      <div className='partners-list' />
    </LazyLoad>
  </div>
</div>
