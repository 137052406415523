import React from 'react'
import { NavLink } from 'react-router-dom';
import { Header1, HeaderIcon } from './../../../../themes/nerdify1/components/Header';
import Holder from './../../../../themes/nerdify1/components/Holder';
import FooterV4 from '../../../../components/FooterV4/Footer.js'
import FormRegistrationV4 from '../../../../components/FormRegistrationV4/FormRegistration.js'
import LocalesPage from './../LocalesPage';

export default (props) => {
  return (
    <LocalesPage {...props}>
      <Header1>
        <Holder>
          <NavLink className='logo-link' to='/'>
            <HeaderIcon iconName='logo-light' />
          </NavLink>
        </Holder>
      </Header1>
      <div className='become'>
        <div className='banner'>
          <div className='wrapper1'>
            <div className='v4-header-subtitle-top'>Skilled enough to</div>
            <div className='v4-header-title'>become a Nerd?</div>
            <div className='v4-header-subtitle-bottom'>We can't wait to have you with us!</div>
          </div>
        </div>
        <section className='become-content'>
          <div className='wrapper1'>
            <h3 className='title-success'>We are always searching for bright minds from the TOP universities to work with us on a freelance basis. Our AI-powered platform is a networking solution for experts in their fields willing to share knowledge.</h3>
            <h4 className='title-base'>Are you in the third year of study or more? Feel free to give it a try!</h4>
            <FormRegistrationV4
              title="Ready to become a member of our freelance team?"
              subTitle="Fill the form - Submit the application"
              desc="The main goal of Nerdify is giving a powerful tool to students, businesses and various institutions
              in the sake of solving their problems. It is of paramount importance for us to provide personalized
              assistance and leave our clients happy. Each Nerd working with us is aware of the principles of
              our company and shares its values. When you choose to apply for the position of a Nerd, you must
              acknowledge that sharing personal info, encouraging betting, drug use or
              cheating are strongly prohibited."
            />
          </div>
        </section>
      </div>

      <FooterV4 {...props} />
    </LocalesPage>
  )
}
