import React from 'react'
import { NavLink } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
import './footer.css'
//import stripBox from './img/stripe-box@2x.png'
import mcafee from './img/mcafee@2x.png'
import moneyback from './img/moneyback@2x.png'
import {
  REACT_APP_CONTACT_EMAIL,
  REACT_APP_ADDRESS_US
} from './../../config';
/*import paypal from './img/paypal@2x.png'*/

const PaymentList = () => {
  return (
    <ul className="footer__payment-list">
      <li className="footer__payment-item">
        <img className="footer__payment-img" src={require('./img/payment/visa.png')}
             srcSet={`${require("./img/payment/visa.png")}, ${require("./img/payment/visa@2x.png")} 2x`} alt=""/>
      </li>
      <li className="footer__payment-item">
        <img className="footer__payment-img" src={require('./img/payment/mc.png')}
             srcSet={`${require("./img/payment/mc.png")}, ${require("./img/payment/mc@2x.png")} 2x`} alt=""/>
      </li>
      <li className="footer__payment-item">
        <img className="footer__payment-img ae" src={require('./img/payment/ae.png')}
             srcSet={`${require("./img/payment/ae.png")}, ${require("./img/payment/ae@2x.png")} 2x`} alt=""/>
      </li>
      <li className="footer__payment-item">
        <img className="footer__payment-img" src={require('./img/payment/discover.png')}
             srcSet={`${require("./img/payment/discover.png")}, ${require("./img/payment/discover@2x.png")} 2x`}
             alt=""/>
      </li>
      {/*
      <li className="payment-item">
        <img className="payment-img" src={require('./img/payment/pp.png')}
             srcSet={`${require("./img/payment/pp.png")}, ${require("./img/payment/pp@2x.png")} 2x`} alt=""/>
      </li>
*/}
      <li className="footer__payment-item">
        <img className="footer__payment-img" src={require('./img/payment/google-pay.png')}
             srcSet={`${require("./img/payment/google-pay.png")}, ${require("./img/payment/google-pay@2x.png")} 2x`}
             alt=""/>
      </li>
      <li className="footer__payment-item">
        <img className="footer__payment-img applepay" src={require('./img/payment/apple-pay.svg')} alt=""/>
      </li>
    </ul>
  )
};


const FooterV4 = (props) => {
  return (
    <footer className='footer-v4'>
      <div className='wrapper1'>
        <div className='row footer-top'>

          <nav className='footer-nav col-5 row'>
            <div className='col-7'>
              <ul>
                <li><NavLink to='/'>Home</NavLink></li>
                <li><NavLink to='/become'>Become a Nerd</NavLink></li>
                <li><NavLink to='/cookies'>Cookie Policy</NavLink></li>
              </ul>
            </div>

            <div className='col-5'>
              <ul>
                <li><NavLink to='/refunds'>Refund Policy</NavLink></li>
                <li><NavLink to='/terms-and-conditions'>Terms of Use</NavLink></li>
                <li><NavLink to='/privacy-policy'>Privacy Policy</NavLink></li>
              </ul>
            </div>
          </nav>

          <div className='col-2 hide-mob' />

          <div className='col-5 right-col'>
            {REACT_APP_ADDRESS_US && <p><b>Our US address:</b> {REACT_APP_ADDRESS_US}</p>}


            <p className='email-box'><b>Contact us:</b> <a href={'mailto:' + REACT_APP_CONTACT_EMAIL} target='_blank'
                                                           rel='noopener noreferrer'>{REACT_APP_CONTACT_EMAIL}</a></p>
            <div className='img-block'>
              <PaymentList />
{/*
              <LazyLoad height={67}>
                <img className='img-strip-box' src={stripBox} alt='stripBox' />
              </LazyLoad>
*/}
              {/*<img className="img-paypal" src={paypal} alt="paypal" />*/}
              <LazyLoad height={50}>
                <img className='img-moneyback' src={moneyback} alt='moneyback' />
              </LazyLoad>
              <LazyLoad height={50}>
                <img className='img-mcafee' src={mcafee} alt='mcafee' />
              </LazyLoad>
            </div>
          </div>
        </div>

        <div className='footer-bottom row space-between'>
          <span className='col-6'>By accessing this website you agree to our <NavLink to='/terms-and-conditions'>Terms of Use</NavLink>, <NavLink
            to='/privacy-policy'>Privacy</NavLink> and <NavLink to='/cookies'>Cookies Policies</NavLink></span>
          {/*      <div className="col-6 right">
        <a className="social-link" href={pageCfg.fbLink} target='_blank' rel='noopener noreferrer'>Facebook</a>
        <a className="social-link" href={pageCfg.twitterLink} target="_blank" rel="noopener noreferrer">Twitter</a>
      </div>*/}
        </div>
      </div>
    </footer>
  )
}

export default FooterV4
