export const dataOurService = {
    title: 'Our pricing policy',
    subtitle: 'Contacting us is free of charge. You need to pay only after you send a task to the Personal Nerd. The 11% fee for our service is already included!',
};

export const dataPersonalNerd = {
    title: 'Interested in who our Personal Nerds are?',
    subtitle: 'These are the "grade-A" students and graduates in our network from US and other world best universities willing to assist you with your task.',
};

export const dataPartners = {
    title: 'Our partners are the best',
};

export const dataTestimonials = [
    {
        name: 'Kaley M.',
        date: 'February 2',
        img: './img/Kaley_M',
        text: "They have excellent customer service, and yes, it may seem a little pricey. However, it's worth every dollar because of the quality."
    },
    {
        name: 'Lucas D.',
        date: 'March 10',
        img: './img/Lucas_D',
        text: 'These guys are so nice and understanding. The first time I used this service I had a million questions and my Nerd answered each of those questions.'
    },
    {
        name: 'Lilly A.',
        date: 'July 12',
        img: './img/Lilly_A',
        text: 'I’m currently taking 5 classes but sometimes I don’t have time to handle everything. Nerdify is great when you have a lot of tasks accumulated. Thank you!'
    },
    {
        name: 'Ethan L.',
        date: 'May 23',
        img: './img/Ethan_L',
        text: 'I am very happy with my use of Nerdify. I never have second thoughts because each time Nerds simply nail it! I recommend it to all my friends.'
    }
];


export const dataFaq = {
    title: 'FAQ',
    items: [
        {
            title: 'What kind of requests do Nerds accept?',
            text: 'The Nerds are ready to help you with any type of request in various areas, however, it must be aligned with corporate and institutional policies. Give them a chance to show what they can do!',
        },
        {
            title: 'How much time will it take?',
            text: 'It always depends on what you need. We offer online services in cooperation with our partners (such as Amazon). In case you need some physical goods delivered, the time is estimated by them.',
        },
        {
            title: 'How much does it cost?',
            text: "Contacting us is free of charge. We do charge a small fee for our service that does not exceed 11% of the final task price. We never charge you before agreeing on the final price with you. You can always take your time to think it over."
        },
        {
            title: 'How do I know my money is safe?',
            text: 'After we calculate the final price we send you a payment link, following which you are taken to the 128-bit encrypted HTTPS payment page. You can use any Credit/Debit card and even pay from your phone! Nerdify does not have access to your personal data and CC info.'
        },
    ]
};
