import React from 'react'
import { NavLink } from 'react-router-dom'
import { REACT_APP_CONTACT_EMAIL } from '../../../../config'
import TextPage from './../TextPage';

export default (props) => (
  <TextPage {...props} >
    <h1>Refund Policy</h1>
    <p>Our company allows our Client to connect with their contractor to avail their goods and services. However, as mentioned in our <NavLink to='/terms-and-conditions'>Terms of Use</NavLink>, their services are not limited to finding internships and colleges, purchasing textbooks, and hiring other contractors.</p>
    <h2>What if the final product/service did not meet my requirements/instructions?</h2>
    <p>You may request a refund from us. However, requests must be submitted 60 days after the product/service has been sent to you. Feel free to message your order number and feedback at <a href={`mailto:${REACT_APP_CONTACT_EMAIL}`} rel="noopener noreferrer">{REACT_APP_CONTACT_EMAIL}</a>. </p>
    <h2>So, what will I do?</h2>
    <div className="section-content">
      <p>Your request will be under investigation for three to five business days. We can refer you to a
         different contractor, and he/she will finish your task for free.
         In that case, please wait for at least 24 hours before we can find another contractor for you.
         Alternatively, we can also allow you to refund your payment to your credit card or available balance.
          Please take note that Nerdify’s virtual money is non-refundable. </p>
    </div>
    <h2>Refund of your subscription plan</h2>
    <div className="section-content">
      <p>You can request a refund for the subscription fee charged on your card up to 30 days from the date of the latest payment. The refund can be issued only if you did not use your bonuses and perks included in the subscription plan since the last payment. In this case, your refund request will be processed within 24 hours and you'll get your money back to the card within 3-5 business days. Please be aware that the bonuses and perks included in your subscription plan are non-refundable and will be withdrawn on the date of the refund issued.</p>
    </div>
  </TextPage>
)
