import Cookies from "./pages/Cookies";
import Refunds from "./pages/Refunds";
import Become from "./pages/Become";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Landing from "./pages/Landing";
import Maintenance from "./pages/Maintenance";
import ThankYou from "./pages/ThankYou";
import NotFound from "./pages/NotFound";

import "./../../themes/nerdify1/assets/css/index.css";
//export const fonts = 'https://fonts.googleapis.com/css?family=Open+Sans:400,700|Lato:400,700,900|Roboto+Slab:400,70|Assistant:400,700|Poppins:400,500,600,700&display=swap';

export const viewport = 'width=640';

export const favicons = [
  {
    type: 'image/png',
    sizes: false,
    href: '/favicon.png',
  },
];

export const routes = [
  {
    path: '/',
    meta: {
      title: 'Nerdify | Top AI-powered solution for students, enterprises and institutions',
      description: 'Get professional help from Personal Nerds with our unique AI-powered solution.',
      keywords: 'concierge service for enterprises, corporate coaching, concierge service for institutions, institutions help service, find a tutor, find employers, find employees, research help, market research help, presentation help, find a book, book delivery, find a textbook, hire help',
      robots: 'noindex, nofollow'
    },
    component: Landing,
    exact: true,
    pageCfg: {
      utmxid: '135049333-7',
    },
  },
  {
    path: '/cookies',
    meta: {
      title: 'Nerdify | Cookie Policy',
      robots: 'noindex, nofollow'
    },
    component: Cookies,
    pageCfg: {}
  },
  {
    path: '/refunds',
    meta: {
      title: 'Nerdify | Refund Policy',
      robots: 'noindex, nofollow'
    },
    component: Refunds,
    pageCfg: {}
  },
  {
    path: '/terms-and-conditions',
    meta: {
      title: 'Nerdify | Terms and Conditions',
      robots: 'noindex, nofollow'
    },
    component: Terms,
    pageCfg: {}
  },
  {
    path: '/privacy-policy',
    meta: {
      title: 'Nerdify | Privacy Policy',
      robots: 'noindex, nofollow'
    },
    component: Privacy,
    pageCfg: {}
  },
  {
    path: '/services-for-students-1',
    meta: {
      title: 'Nerdify | Top AI-powered solution for students, enterprises and institutions',
      description: 'Get professional help from Personal Nerds with our unique AI-powered solution.',
      keywords: 'concierge service for enterprises, corporate coaching, concierge service for institutions, institutions help service, find a tutor, find employers, find employees, research help, market research help, presentation help, find a book, book delivery, find a textbook, hire help',
      robots: 'noindex, nofollow'
    },
    //component: Landing,
    //exact: true,
    pageCfg: {
      redirectTo: '/',
    }
  },
  {
    path: '/services-for-students-2',
    meta: {
      title: 'Nerdify | Top AI-powered solution for students, enterprises and institutions',
      description: 'Get professional help from Personal Nerds with our unique AI-powered solution.',
      keywords: 'concierge service for enterprises, corporate coaching, concierge service for institutions, institutions help service, find a tutor, find employers, find employees, research help, market research help, presentation help, find a book, book delivery, find a textbook, hire help',
      robots: 'noindex, nofollow'
    },
    //component: Landing,
    //exact: true,
    pageCfg: {
      redirectTo: '/',
    }
  },
  {
    path: '/services-for-students-3',
    meta: {
      title: 'Nerdify | Top AI-powered solution for students, enterprises and institutions',
      description: 'Get professional help from Personal Nerds with our unique AI-powered solution.',
      keywords: 'concierge service for enterprises, corporate coaching, concierge service for institutions, institutions help service, find a tutor, find employers, find employees, research help, market research help, presentation help, find a book, book delivery, find a textbook, hire help',
      robots: 'noindex, nofollow'
    },
    //component: Landing,
    //exact: true,
    pageCfg: {
      redirectTo: '/',
    }
  },
  {
    path: '/services-for-students-4',
    meta: {
      title: 'Nerdify | Top AI-powered solution for students, enterprises and institutions',
      description: 'Get professional help from Personal Nerds with our unique AI-powered solution.',
      keywords: 'concierge service for enterprises, corporate coaching, concierge service for institutions, institutions help service, find a tutor, find employers, find employees, research help, market research help, presentation help, find a book, book delivery, find a textbook, hire help',
      robots: 'noindex, nofollow'
    },
    //component: Landing,
    //exact: true,
    pageCfg: {
      redirectTo: '/',
    }
  },
  {
    path: '/services-for-students-5',
    meta: {
      title: 'Nerdify | Top AI-powered solution for students, enterprises and institutions',
      description: 'Get professional help from Personal Nerds with our unique AI-powered solution.',
      keywords: 'concierge service for enterprises, corporate coaching, concierge service for institutions, institutions help service, find a tutor, find employers, find employees, research help, market research help, presentation help, find a book, book delivery, find a textbook, hire help',
      robots: 'noindex, nofollow'
    },
    //component: Landing,
    //exact: true,
    pageCfg: {
      redirectTo: '/',
    }
  },
  {
    path: '/services-for-students',
    meta: {
      title: 'Nerdify | Top AI-powered solution for students, enterprises and institutions',
      description: 'Get professional help from Personal Nerds with our unique AI-powered solution.',
      keywords: 'concierge service for enterprises, corporate coaching, concierge service for institutions, institutions help service, find a tutor, find employers, find employees, research help, market research help, presentation help, find a book, book delivery, find a textbook, hire help',
      robots: 'noindex, nofollow'
    },
    //component: Landing,
    //exact: true,
    pageCfg: {
      redirectTo: '/',
    }
  },
  {
    path: '/services-for-enterprises',
    meta: {
      title: 'Nerdify | Top AI-powered solution for students, enterprises and institutions',
      description: 'Get professional help from Personal Nerds with our unique AI-powered solution.',
      keywords: 'concierge service for enterprises, corporate coaching, concierge service for institutions, institutions help service, find a tutor, find employers, find employees, research help, market research help, presentation help, find a book, book delivery, find a textbook, hire help',
      robots: 'noindex, nofollow'
    },
    //component: Landing,
    //exact: true,
    pageCfg: {
      redirectTo: '/',
      //activeItem: 'Enterprises',
    },
  },
  {
    path: '/services-for-institutions',
    meta: {
      title: 'Nerdify | Top AI-powered solution for students, enterprises and institutions',
      description: 'Get professional help from Personal Nerds with our unique AI-powered solution.',
      keywords: 'concierge service for enterprises, corporate coaching, concierge service for institutions, institutions help service, find a tutor, find employers, find employees, research help, market research help, presentation help, find a book, book delivery, find a textbook, hire help',
      robots: 'noindex, nofollow'
    },
    //component: Landing,
    //exact: true,
    pageCfg: {
      redirectTo: '/',
      //activeItem: 'Institutions',
    },
  },
  {
    path: '/become',
    meta: {
      title: 'Nerdify | Become a Nerd',
      description: 'Join freelance Nerdify family! Become an online tutor in our Network to earn money.',
      keywords: 'become a freelance tutor, become a nerd, become a tutor online, online teaching jobs, online english teaching jobs, online college teaching jobs, online teaching jobs part time, how to become an online teacher, best online english teaching jobs, how to teach english online, become an online teacher',
      robots: 'noindex, nofollow'
    },
    component: Become,
    pageCfg: {}
  },
  {
    path: "/maintenance",
    meta: {
      title: "",
      description: "",
      keywords: "",
      robots: "noindex, nofollow",
    },
    component: Maintenance,
    pageCfg: {},
  },
  {
    path: '/thank-you',
    meta: {
      title: 'Nerdify | #1 Personal Assistant for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'personal assistant for students, someone to edit my paper, proofread my essay, homework help from online tutor, online assignment tutoring, need help writing, concierge service for students, writing tips, nerds online writing help, help to write my paper, pay someone, title help, outline help, essay papers, essay writing tutor, my homework, homework help online, my assignment, college writing, university writing, need help writing, master thesis proofreading, paper checker, essay revision, formatting, need help writing, essay tutoring, college paper, homework tutoring, help with assignments, editing, proofreading',
      robots: 'noindex, nofollow',
    },
    component: ThankYou,
    pageCfg: {
      backLink: true,
    },
  },
  {
    meta: {
      title: '',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: NotFound,
    pageCfg: {}
  },
]
