import React, { Component } from 'react'
import Icon from './../SvgIcons'
import classnames from "classnames";
import { Dropdown, Panel } from './../Dropdown/Dropdown';
import './FormRegistration.css'

const educational = [
  "High School",
  "College",
  "University",
  "Masters",
  "PhD"
];

class FormRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      education: "High School",
      isFormValid: true
    };

    this.onOpenChange = this.onOpenChange.bind(this);
    this.onOpenClick = this.onOpenClick.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.inputs = document.getElementsByClassName('required');
  }

  onOpenChange(value) {
    this.setState({ isOpen: value });
  }

  onInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    //e.target.classList.remove("is_error");
    e.target.value.trim() ? e.target.classList.remove("is_error") : e.target.classList.add("is_error");

    if (document.getElementsByClassName('is_error').length <= 0) {
      this.setState({ isFormValid: true });
    } else {
      this.setState({ isFormValid: false });
    }
  }

  onOpenClick() {
    const { loading } = this.props;
    if (loading) {
      return;
    }
    this.onOpenChange(true);
  }

  onSelect(value) {
    this.setState({ education: value });
    this.onOpenChange(false);
  }

  onSubmit(e) {
    e.preventDefault();
    var isValidated = true;
    for (var i = 0; i < this.inputs.length; i++) {
      if (this.inputs[i].value.trim() === '') {
        this.inputs[i].classList.add("is_error");
        this.setState({ isFormValid: false });
        isValidated = false;
      }
    }

    if (isValidated) {
      //this.setState({ isFormValid: false });
      //$(this).closest("form").submit();
      alert("Thank you! We'll contact you within a week if you are shortlisted!");
    }
  }

  render() {
    const { isOpen, education } = this.state;
    const {title, subTitle, desc} = this.props;

    return (
      <div className="form-reg">
        <h2 className="title">{title}</h2>
        <h3 className="sub-title">{subTitle}</h3>

        <form className="form-box">
          <div className={classnames("form-box-field", { is_error: this.isNameValid })}>
            <label>Your full name</label>
            <input className="block required" type='text' name='fullname' placeholder="ex. John Peterson" onChange={(e) => this.onInputChange(e)} />
          </div>

          <div className={classnames("form-box-field")}>
            <label>We can contact you via</label>
            <div className="row vertical">
              <div className={classnames("form-box-field no-padd", { is_error: this.isNameValid })}>
                <input className="block required" type='text' name='phone' placeholder="Phone number" onChange={(e) => this.onInputChange(e)} />
              </div>
              <span className="symbol">&</span>
              <div className={classnames("form-box-field no-padd", { is_error: this.isNameValid })}>
                <input className="block required" type='text' name='email' placeholder="Email" onChange={(e) => this.onInputChange(e)} />
              </div>
            </div>
          </div>

          <div className="form-box-field">
            <label>Your current highest educational level is</label>
            <span className="block btn-dropdown" onClick={this.onOpenClick}>{education || 'Educational level'}<Icon className={'svg-icon' + (isOpen ? ' is-open' : '')} iconName='icon-triangle' /></span>
            <Dropdown onOpenChange={this.onOpenChange} width="widthMd" isOpen={isOpen}>
              <Panel>
                <ul>
                  {educational.map(item => {
                    return (
                      <li className={'select-item' + (education === item ? ' active' : '')} key={item}>
                        <div onClick={() => { this.onSelect(item) }}>{item}</div>
                      </li>
                    )
                  })}
                </ul>
              </Panel>
            </Dropdown>
          </div>

          <div className="form-box-field">  {/*is-error*/}
            <label>You are skilled in</label>
            <input className="block required" type='text' name='skilled' placeholder="Everything" onChange={(e) => this.onInputChange(e)} />
            {/*<span className="error">Required filed</span>*/}
          </div>

          <div className="form-box-field">
            <label>You see yourself on our Network because</label>
            <textarea className="required" name="yourself" placeholder="I have relevant previous experience" onChange={(e) => this.onInputChange(e)} />
          </div>

{/*
          <p className="add-cv">We want to know more about you: <a>Add a CV</a></p>

          <div className="row vertical file-box">
            <a className="file-delete"><Icon className='svg-icon' iconName='icon-delete' /></a>
            <a className="file-name">Some file.jpg</a>
          </div>
*/}

          <p className="desc-box">{desc}</p>

          <button className="btn" type="button" onClick={(e) => this.onSubmit(e)} disabled={!this.state.isFormValid}>Apply Now</button>

        </form>

      </div>
    )
  }
}

export default FormRegistration
