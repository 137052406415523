import React from 'react'
import Icon from './../Icons';

import classnames from "classnames";

import './header.css'

const Header = ({className, ...rest}) => <header className={classnames('header', className)} {...rest} />

export const HeaderIcon = (props) => <Icon className='svg-icon logo-icon' {...props} />

export const Header1 = (props) => <Header className={'header_1'} {...props} />;
export const Header2 = (props) => <Header className={'header_2'} {...props} />;
export const Header3 = (props) => <Header className={'header_3'} {...props} />;
export const Header4 = (props) => <Header className={'header_4'} {...props} />;
export const HeaderBlog = (props) => <Header className={'header_blog'} {...props} />;
