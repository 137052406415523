import React from 'react';

import textBackIcons from './img/icons.svg';

import {
  TextBack,
  TextBackForm as TextBackFormBase,
} from './../../../../base_components/TextBack';

import {
  TextBackModalHOC,
  TextBackWithEmailModalHOC
} from '../../../../base_components/TextBack/TextBackModal';

import './index.css';

import { ModalTextBackWithEmailSuccess, ModalTextBackWithEmailRepeat } from './../Modal';

export const TextBackTheme1 = (props) => <TextBack {...props} className={'textback_1'} />;
export const TextBackTheme2 = (props) => <TextBack {...props} className={'textback_2'} />;

export const TextBackForm = TextBackModalHOC(TextBackWithEmailModalHOC((props) => {
  return <TextBackFormBase {...props} textBackIcons={textBackIcons} />
})(ModalTextBackWithEmailSuccess, ModalTextBackWithEmailRepeat));
