import React, { Component } from "react";

import OurServiceCostsV4 from '../../../../components/OurServiceCostsV4/OurServiceCostsV4.js'
import PartnersV4 from '../../../../components/PartnersV4/PartnersV4.js'
import PersonalNerdBlockV4 from '../../../../components/PersonalNerdBlockV4/PersonalNerdBlockV4.js'
import FeaturedV4 from '../../../../components/FeaturedV4/FeaturedV4.js'
import PressBlock from '../../../../components/PressBlock/PressBlock.js'
import RateBlockV4 from '../../../../components/RateBlockV4/RateBlockV4.js'
import TestimonialsV4 from '../../../../components/TestimonialsV4/TestimonialsV4.js'
import SliderV4 from '../../../../components/SliderV4/Slider'
import FAQV4 from '../../../../components/FAQV4/FAQ.js'
import FooterV4 from '../../../../components/FooterV4/Footer.js'

import { landingsToSave } from '../../../../config';
import { saveQueryToStorage } from '../../../../utils';
import {
  dataFaq,
  dataOurService,
  dataPartners,
  dataPersonalNerd,
  dataTestimonials
} from './data';

import LocalesPage from './../LocalesPage';
import { Hero1_2 } from './../../../../themes/nerdify1/components/Hero';
import Holder from './../../../../themes/nerdify1/components/Holder';
import { Header1, HeaderIcon } from './../../../../themes/nerdify1/components/Header';
import { TextBack1, TextBack2 } from '../../blocks/TextBack';
import { NavLink } from 'react-router-dom';
import Text from "./../../../../base_components/Text";
import How, { HowList, HowImg } from './../../../../themes/nerdify1/components/How';

import { Hiw1, HiwImg, HiwList, HiwItem, HiwText } from '../../../../themes/nerdify1/components/Hiw';
import { Section1 } from '../../../../themes/nerdify1/components/Section';

class Landing extends Component {
  componentDidMount() {
    const { location } = this.props;
    const { pathname } = location;

    if (landingsToSave.indexOf(pathname) > -1) {
      saveQueryToStorage({ isSavePathForTransition: true });
    }

    const savedPath = localStorage.getItem('savedPath');
    const isTransition = savedPath && pathname !== savedPath && pathname === '/';

    if (isTransition) {
      this.props.history.push(savedPath)
    }
  }

  render() {
    const { pageCfg } = this.props;

    return (
      <LocalesPage {...this.props}>

        <Header1>
          <Holder>
            <NavLink className='logo-link' to='/'>
              <HeaderIcon iconName='logo-light-1' />
            </NavLink>
          </Holder>
        </Header1>

        <Hero1_2>
          <Holder>
            <h1 className="hero-title">{pageCfg.headline || <Text>Dealing with a heavy workload?</Text>}</h1>
            <h4 className="h4 hero-subtitle">Get professional help from Personal Nerds with an AI-powered solution</h4>
            <TextBack1 />
          </Holder>
        </Hero1_2>

        <How>
          <h3 className={'h3'}>What can you get at Nerdify?</h3>

          <HowList
            {...this.props}
            items={{
              Enterprises: [
                {
                  text: 'Organization of personal & corporate coaching sessions',
                  img: <HowImg img={'businesses-icon-1'} />
                },
                {
                  text: 'Help with market research, white papers, etc.',
                  img: <HowImg img={'businesses-icon-2'} />
                },
                {
                  text: 'Putting together company presentations',
                  img: <HowImg img={'businesses-icon-3'} />
                },
                {
                  text: 'Finding motivated and skilled people for vacancies',
                  img: <HowImg img={'businesses-icon-4'} />
                }
              ],
              Students: [
                {
                  text: 'List of open positions in companies and programs',
                  img: <HowImg img={'students-icon-1'} />
                },
                {
                  text: 'Delivery of the necessary learning materials',
                  img: <HowImg img={'students-icon-2'} />
                },
                {
                  text: 'Arrangement of a tutoring session',
                  img: <HowImg img={'students-icon-3'} />
                },
                {
                  text: 'Guidelines to facilitate the application process',
                  img: <HowImg img={'students-icon-4'} />
                }
              ],
              Institutions: [
                {
                  text: 'Internship opportunities for the bright minds',
                  img: <HowImg img={'universities-icon-1'} />
                },
                {
                  text: 'Help in finding and recruiting talented individuals',
                  img: <HowImg img={'universities-icon-2'} />
                },
                {
                  text: 'Development of internal communication strategies',
                  img: <HowImg img={'universities-icon-3'} />
                },
                {
                  text: 'Guidelines to facilitate the application process',
                  img: <HowImg img={'universities-icon-4'} />
                }
              ],
            }}
          >
          </HowList>

          <p className="how-line">Happy to assist you in everything listed above and even more as long as it doesn't violate any college or corporate policies.</p>
        </How>

        <div className="hr" />

        <Hiw1>
          <h3 className='h3'>It's as easy as a pie</h3>

          <HiwList>
            <HiwItem>
              <HiwImg img={'hiw1'} />
              <HiwText>
                <p className="p">Contact us and describe your task</p>
              </HiwText>
            </HiwItem>
            <HiwItem>
              <HiwImg img={'hiw2'} />
              <HiwText>
                <p className="p">Get a Nerd assigned to you in a few mins</p>
              </HiwText>
            </HiwItem>
            <HiwItem>
              <HiwImg img={'hiw3'} />
              <HiwText>
                <p className="p">Nail your task hassle-free</p>
              </HiwText>
            </HiwItem>
          </HiwList>

        </Hiw1>

        <SliderV4
          themesCfg={5}
          indicatorsCfg={1}
          imagesCfg={6}
          controlsCfg={1}
          renderSubTitle={() => {
            return 'The Nerd will agree to help you out only if your request does not violate any institutional policies.'
          }}
        />
        <OurServiceCostsV4 data={dataOurService} />
        <PersonalNerdBlockV4 data={dataPersonalNerd} />
        <PartnersV4 data={dataPartners} />
        <FeaturedV4
          className='featured-blockV4'
        />
        <PressBlock />

        <RateBlockV4
          className='rate-blockV4'
        />

        <TestimonialsV4
          // classNamesCfg={1}
          // itemsCfg={1}
          moreReviews
          data={dataTestimonials}
        />


        <Section1>
          <Holder>
            <h1 className="h1">Thinking about hiring a Nerd for help?</h1>
            <h5 className="h5">Just get in touch with us to learn what you can get at Nerdify!</h5>
            <TextBack2 />
          </Holder>
        </Section1>

        <div className="hr" />

        <FAQV4
          // classNamesCfg={2}
          // titlesCfg={2}
          // itemsCfg={1}
          data={dataFaq}
        />

        <FooterV4
          {...this.props}
        />

      </LocalesPage>
    )
  }
}

export default Landing
