import React, { Component } from 'react'
import './slider.css'
import LazyLoad from 'react-lazyload';
import Icon from './../SvgIcons'
import ReactSwipe from 'react-swipe'
import sliderCfg from './config'

/**
 * @param {Object} props
 * @param {Number} props.indicatorsCfg
 * @param {Number} props.activeIndex
 */

/**
 * @param {Object} props
 * @param {Number} props.controlsCfg
 * @param {Function} props.handleOnClickPrev
 * @param {Function} props.handleOnClickNext
 */

const SliderControls = (props) => {
  const controls = sliderCfg.controls[props.controlsCfg]
  const hasControls = controls && controls.length > 0

  if (!hasControls) {
    return null
  }

  return [
    <div
      onClick={props.handleOnClickPrev}
      className='carousel-control left ga_ev'
      data-slide='prev'
      data-gacat='examples_arrow' data-galab='examples_arrow left'
      data-gaact='left' key='carousel-control-left'>
      {controls.map((item, key) => {
        return (
          <LazyLoad key={`carousel-control-left-${key}`}>
            <Icon
              className={`svg-icon ${item.className || ''}`}
              iconName={`${item.iconName}`}
            />
          </LazyLoad>
        )
      })}

    </div>,
    <span key="carousel-control-desc" className="carousel-control-desc">Swipe to see examples</span>,
    <div
      onClick={props.handleOnClickNext}
      className='carousel-control right ga_ev'
      data-slide='next'
      data-gacat='examples_arrow' data-galab='examples_arrow right'
      data-gaact='right' key='carousel-control-right'>
      {controls.map((item, key) => {
        return (
          <LazyLoad key={`carousel-control-right-${key}`}>
            <Icon
              className={`svg-icon ${item.className || ''}`}
              iconName={`${item.iconName}`}
            />
          </LazyLoad>
        )
      })}
    </div>
  ]
}

/**
 * @class Slider
 * @memberOf Components
 * @param {Object} props
 */

class Slider extends Component {
  constructor (props) {
    super(props)

    this._handleOnClickNext = this._handleOnClickNext.bind(this)
    this._handleOnClickPrev = this._handleOnClickPrev.bind(this)
    this._setActiveControl = this._setActiveControl.bind(this)
    this._scrollTo = this._scrollTo.bind(this)

    this.state = {
      activeIndex: 0
    }
  }

  static defaultProps = {
    options: {
      speed: 800,
      continuous: true,
      auto: 9000,
      disableScroll: false,
      stopPropagation: true
    }
  }

  _handleOnClickPrev () {
    this.carousel.prev()
  }

  _handleOnClickNext () {
    this.carousel.next()
  }

  _setActiveControl (index) {
    const {imagesCfg} = this.props
    const images = sliderCfg.images[imagesCfg]
    const imagesLength = images && images.length

    this.setState({
      activeIndex: imagesLength === 2 ? index % 2 : index
    })
  }

  _scrollTo (index) {
    this.carousel.slide(index)
  }

  render () {
    const {themesCfg, imagesCfg, controlsCfg} = this.props
    const iphoneIcon = sliderCfg.themes[themesCfg].iphoneIcon
    const images = sliderCfg.images[imagesCfg]
    const options = this.props.options
    const imagesLength = images && images.length
    const hasImages = imagesLength > 0

    if (!hasImages) {
      return null
    }

    const mergedOptions = Object.assign(options, {
      callback: this._setActiveControl
    })

    return (
      <div className='slider'>
        <div className='cell'>
          <div id='myCarousel' className='carousel slide slider1'>

            <SliderControls
              handleOnClickPrev={this._handleOnClickPrev}
              handleOnClickNext={this._handleOnClickNext}
              controlsCfg={controlsCfg}
            />

            {/* <SliderIndicator
              indicatorsCfg={indicatorsCfg}
              activeIndex={activeIndex}
              setActiveByIndex={this._setActiveControl}
              scrollTo={this._scrollTo}
            /> */}

            <div className='iphone-wrapper'>
              <LazyLoad>
                <Icon className='svg-icon iphone-img' iconName={iphoneIcon.iconName} />
              </LazyLoad>
              <div className='carousel-inner'>
                <ReactSwipe
                  ref={(carousel) => (this.carousel = carousel)}
                  key={imagesLength}
                  swipeOptions={mergedOptions}>
                  {images.map((item, key) => {
                    return (
                      <div className='item' key={`slider-item-${key}`}>
                        <LazyLoad>
                        <img
                          src={require(`${item.img}`)}
                          alt={item.alt} />
                        </LazyLoad>
                      </div>
                    )
                  })}
                </ReactSwipe>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/**
 * @param {Object} props
 * @param {Array} props.indicators
 */

const SliderWrapper = (props) => {
  const themesCfg = sliderCfg.themes[props.themesCfg]
  const title = themesCfg.title
  // const subTitle = themesCfg.subTitle

  return (
    <div className={themesCfg.className}>
      <div className='wrapper1'>
        {title && <p className={title.className}>{title.text}</p>}
        {props.renderSubTitle && <p className='go-slider-subtitle'>
          {props.renderSubTitle()}
        </p>}
        <Slider {...props} />
      </div>
    </div>
  )
}

export default SliderWrapper
