import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { REACT_APP_PP_EMAIL } from '../../../../config'
import TextPage from './../TextPage';

export default class Privacy extends Component {
  constructor(props) {
    super(props)

    this._handleOnClickToggleActive = this._handleOnClickToggleActive.bind(this)
    this._handleWindowSizeChange = this._handleWindowSizeChange.bind(this)
    this._handleOnClickClose = this._handleOnClickClose.bind(this)

    this.state = {
      activeIndex: undefined,
      windowWidth: typeof window !== 'undefined'
        ? window.innerWidth
        : undefined,
      contentHeight: {}
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this._handleWindowSizeChange)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this._handleWindowSizeChange)
    }
  }

  _handleWindowSizeChange() {
    if (typeof window !== 'undefined') {
      this.setState({ windowWidth: window.innerWidth })
    }
  }

  _handleOnClickToggleActive(e) {
    const { activeIndex, windowWidth } = this.state

    if (windowWidth >= 1200) {
      return false
    }

    const index = Number(e.target.dataset.index)
    const isSameIndex = activeIndex === index
    const el = typeof document !== 'undefined'
      ? document.querySelector('#content-' + index)
      : undefined

    if (!el) {
      return false
    }

    this.setState({
      activeIndex: isSameIndex ? undefined : index,
      contentHeight: {
        [index]: isSameIndex ? undefined : el.clientHeight
      }
    })
  }

  _handleOnClickClose(e) {
    e.preventDefault()

    const { activeIndex } = this.state

    this.setState({
      activeIndex: undefined,
      contentHeight: {
        [activeIndex]: undefined
      }
    })
  }

  render() {
    const { activeIndex, contentHeight } = this.state

    return (
      <TextPage {...this.props} >
        <h1>Privacy Policy</h1>
        <div className='accordion' id='accordion2'>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2 className='accordion-toggle question' onClick={this._handleOnClickToggleActive} data-index='1'>1. Introduction to our Privacy Policy</h2>
            </div>
            <div className={`accordion-body collapse ${activeIndex === 1 ? 'in' : ''}`} style={{ height: contentHeight[1] }}>
              <div className='accordion-inner section-content' id='content-1'>
                <p>Greetings, user! Our privacy policy informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service. This, however, does not apply to any information you share with third parties. Privacy policies are constantly evolving. Hence, this page may be updated and entered into force on the date it was last updated. <br /> Last updated: May 25, 2018.</p>
                <div className='accordion-toggle close-btn' onClick={this._handleOnClickClose}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2 className='accordion-toggle question' onClick={this._handleOnClickToggleActive} data-index='2'>2. Information Collection: How We Collect It </h2>
            </div>
            <div className={`accordion-body collapse ${activeIndex === 2 ? 'in' : ''}`} style={{ height: contentHeight[2] }}>
              <div className='accordion-inner section-content' id='content-2'>
                <p>Your phone number is collected and stored in our database because it plays a significant role in providing our services to you. You may check our website if you do not want to share your number, but bear in mind that you will not gain access to our services.</p>

                <h3>2.1. Types of data</h3>
                <p>2.1.1. The information you directly send to Nerdify.</p>
                <p>This includes your email address, phone number, education, photos, and the like.</p>
                <p>2.1.2. Information Nerdify collects.</p>
                <p>Your device type, screen resolution, operating system, etc. We also collect your IP address, location, duration, and frequency of your visit. These are gathered through our cookies and other tracking technologies. Learn more in our <NavLink to='/cookies'>cookie policy</NavLink>.</p>
                <p>2.1.3. Information we receive from the third parties.</p>
                <p>The third party services include social media. Information from your social media can be collected if you decide to share it with us.</p>
                <p>2.1.4. Users’ Sensitive Information.</p>
                <p>Sensitive information consists of your race, gender, ethnicity, religious beliefs, philosophy, etc. We will not gather this information - you choose to share it at your own risk.</p>

                <h3>2.2. Online Privacy Protection: Children</h3>
                <p>The platform is not meant to be used by individuals under 18 years of age, and we will not gather any personal information about the minors.</p>
                <div className='accordion-toggle close-btn' onClick={this._handleOnClickClose}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2 className='accordion-toggle question' onClick={this._handleOnClickToggleActive} data-index='3'>3. Consent for the Use of Your Information</h2>
            </div>
            <div className={`accordion-body collapse ${activeIndex === 3 ? 'in' : ''}`} style={{ height: contentHeight[3] }}>
              <div className='accordion-inner section-content' id='content-3'>
                <p>The information you choose to share with us if for the improvement of our services and to grant you access to our services and communicate with you.</p>
                <h3>3.1. Using your Data for Nerdify Operation</h3>
                <p>This refers to the following:</p>
                <ul className='ul-typeV4'>
                  <li>Improving website performance;</li>
                  <li>Processing financial transactions;</li>
                  <li>Developing new virtual products and services;</li>
                  <li>Monitoring and analyzing your online behavior;</li>
                  <li>Detecting, preventing, and investigating fraudulent cases/occurrences;</li>
                  <li>Preventing anyone from violating our <NavLink to='/terms-and-conditions'>Terms of Use</NavLink>, Privacy Policy, and the like.</li>
                  <li>Safeguarding the rights of our users</li>
                  <li>Conducting administrative tasks (Ex: Statistical analysis)</li>
                </ul>

                <h3>3.2. Using your Data for Communication Purposes</h3>
                <p>We collect your personal information for the purpose of:</p>
                <ul className='ul-typeV4'>
                  <li>Customer service and support;</li>
                  <li>Attending to your inquiries;</li>
                  <li>Informing you of the latest news about our services;</li>
                  <li>Conducting online surveys.</li>
                </ul>

                <h3>3.3. Using your Data for Advertising Purposes</h3>
                <p>Your information can be used for advertising to:</p>
                <ul className='ul-typeV4'>
                  <li>Give you personalized content and features;</li>
                  <li>Promote content that may possibly be relevant to you.</li>
                </ul>

                <h3>3.4. Using your Data for Legal Obligations</h3>
                <p>Don’t worry, the information you shared with us are used legally. Here’s how: </p>
                <ul className='ul-typeV4'>
                  <li>Your phone number is used to provide you with our free and paid services; </li>
                  <li>It is to prevent cases of fraud, database safety, and protect your (and other users’) privacy. </li>
                </ul>
                <div className='accordion-toggle close-btn' onClick={this._handleOnClickClose}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2 className='accordion-toggle question' onClick={this._handleOnClickToggleActive} data-index='4'> 4. Sharing Information and Affiliate Disclosure</h2>
            </div>
            <div className={`accordion-body collapse ${activeIndex === 4 ? 'in' : ''}`} style={{ height: contentHeight[4] }}>
              <div className='accordion-inner section-content' id='content-4'>
                <p>Your personal data can be shared with the third parties for legal obligations and to protect our legal rights.</p>
                <h3>4.1. Sharing Information With Service Providers (Third Parties)</h3>
                <p>Service providers are used to improve our website performance, send emails and messages, process payments, and provide technical support. We grant these third parties the right to collect and use your information but rest assured that it will not be beyond our authorization.</p>
                <h3>4.2. Sharing Information With Other Countries</h3>
                <p>Your information may be shared with other countries to comply with their respective data protection laws and in correspondence to our privacy policy.</p>
                <h3>4.3. Sharing Information For Security Reasons</h3>
                <p>Our employees are trained to handle your information. In fact, only our authorized employees have access to your personal information. Rest assured that your data is safe from us. We also conduct hardware and software checks for maximum security.</p>
                <h3>4.4. Affiliate Disclosure</h3>
                <p>Nerdify-it.com website is a participant of the Nerdify Partnership Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees. As part of this Nerdify Partnership Program, nerdify-it.com website contains customized links and/or contact forms, provided by Nerdify, to track the referrals to their service. This program utilizes cookies to track visits for the purposes of assigning commission on these sales.</p>
                <h3>4.5. Personal Information and Partnership Program</h3>
                <p>Some of the contact forms on nerdify-it.com website are affiliate contact forms, which means that nerdify-it.com may earn a commission if you submit a contact form or make a purchase using the phone number you’ve provided in a contact form. When you make a purchase, the price you pay will be the same whether you use the affiliate contact form or text the vendor directly or go directly to the vendor's website using a non-affiliate link. By using the affiliate contact forms and links, you are helping support the nerdify-it.com website.</p>
                <p>By clicking "Text me" button on a contact form, you agree to send your text-back request to Nerdify who agrees to use your personal information (phone number) according to this Privacy Policy. Nerdify-it.com website provides personal information collected from you to Nerdify in order to provide customer service and assist you with your purchases. Your personal information will not be shared with 3rd parties other than specified on this Privacy Policy page.</p>
                <div className='accordion-toggle close-btn' onClick={this._handleOnClickClose}>close</div>
              </div>
            </div>
          </div>

          <div className='accordion-group'>
            <div className='accordion-heading'>
              <h2 className='accordion-toggle question' onClick={this._handleOnClickToggleActive} data-index='5'>5. Your Rights on this Platform</h2>
            </div>
            <div className={`accordion-body collapse ${activeIndex === 5 ? 'in' : ''}`} style={{ height: contentHeight[5] }}>
              <div className='accordion-inner section-content' id='content-5'>
                <ul className='ul-typeV4'>
                  <li>To restrict us and/or withdraw from processing your data.</li>
                  <li>To transfer and review your information.</li>
                  <li>To unsubscribe from marketing-related news and emails by clicking “unsubscribe”.</li>
                  <li>To complain to your Data Protection Authority (DPA) on how the company is using your information.</li>
                  <li>To decline cookies and other tracking technologies. Please read our <NavLink to='/cookies'>cookie policy</NavLink> for more information.</li>
                </ul>
                <p>Comments? Suggestions? Requests? Send us an email at <a href={`mailto:${REACT_APP_PP_EMAIL}`} rel="noopener noreferrer">{REACT_APP_PP_EMAIL}</a>.</p>
                <div className='accordion-toggle close-btn' onClick={this._handleOnClickClose}>close</div>
              </div>
            </div>
          </div>
        </div>

      </TextPage>
    )
  }
}
