import React from 'react'
import rateImg from './img/rate.svg'
import './rateBlockV4.css'

const RateBlock = (props) => {
  return (
    <div className={`${props.className}`}>
      <div className='wrapper2'>
        <div className='title'>Rated 4.7/5 based on</div>
        <div className="subtitle">Ernst &amp; Young Client Satisfaction Research</div>
        <div className='rating'>
            <img src={rateImg} alt="" />
          {/*<Icon className='svg-icon' iconName='rate-icon' />*/}
        </div>
      </div>
    </div>
  )
}

export default RateBlock
