import React from 'react'
import './featuredV4.css'

const FeaturedV4 = (props) => {
    return (
        <div className={`${props.className}`}>
            <div className='wrapper2'>
                <div className='quote'><span className='mark open'>“</span>Coolest startup<br className='mobile-only' /> in edu tech world<span className='mark close'>„</span></div>
                <div className="quote-author">Seth Rosenberg, Facebook Messenger PM<br className="mobile-only" /> at F8 conference</div>
            </div>
        </div>
    )
}

export default FeaturedV4
