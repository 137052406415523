import React from 'react'
import Icons from './icons.svg'

export default (props) => {
  return (
    <svg className={props.className + ' ' + props.iconName}>
      <use xlinkHref={`${Icons}#${props.iconName}`} />
    </svg>
  )
}
